<template>
  <v-skeleton-loader
    type="image"
    class="custom-fields"
    v-if="!dataCustomFields"
  />
  <v-card
    class="custom-fields"
    v-else
  >
    <v-card-title>{{$t('t.AdditionalInformation')}}</v-card-title>
    <v-card-text>
      <grid item-min-width="300px">
        <div
          v-for="(c, idx) in dataCustomFields"
          :key="idx + '_customField'"
        >
          <long-text-field
            class="custom-field"
            :clearable="clearable"
            :label="$t('t.Info') + ` ${idx + 1 }`"
            :text.sync="dataCustomFields[idx]"
            @update:text="setValue(idx, $event)"
          />
        </div>
      </grid>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  components: {
    LongTextField: () => import('@/components/long-text-field'),
    Grid: () => import('@/components/grid')
  },
  computed: {
  },
  data () {
    return {
      dataCustomFields: [],
      dataDocumentEmit: this.$nextTickDedup(this.emitDocument)
    }
  },
  methods: {
    setValue (idx, value) {
      this.dataCustomFields[idx] = value
      this.dataDocumentEmit()
    },
    emitDocument () {
      if (!this.lodash.isEqual(this.value, this.dataCustomFields)) {
        this.$emit('input', this.lodash.cloneDeep(this.dataCustomFields))
      }
    }
  },
  props: {
    clearable: {
      type: Boolean,
      default: false
    },
    value: Array
  },
  watch: {
    value: {
      immediate: true,
      handler (v) {
        this.dataCustomFields = v?.length ? this.lodash.cloneDeep(v) : []
      }
    }
  }
}
</script>

<style lang="stylus" scoped></style>
